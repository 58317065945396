import type { WatchSource } from 'vue'
import type { MaybeRef } from '~/ts/types/common'

export type UseFormatDateOptions = {
    format?: '' | string
    timezoneForFormat?: '' | string
    relativeCalendar?: boolean
    autoUpdate?: boolean
    autoUpdateMs?: number
    watchValue?: WatchSource<number>
}

export const useFormatDate = (
    timestamp: MaybeRef<number>,
    options: UseFormatDateOptions = {}
): ComputedRef<string | undefined> => {
    const {
        format = DATE_DISPLAY_DATE_FORMAT,
        timezoneForFormat = undefined,
        relativeCalendar = false,
        autoUpdate = false,
        autoUpdateMs = 1000 * 60,
        watchValue = undefined
    } = options

    const date = ref<number>(toValue(timestamp))
    const dateUpdatedTimes = ref<number>(0)

    const autoUpdateTimer = useTimer(
        () => updateDate(date.value),
        autoUpdateMs,
        { interval: true }
    )

    const updateDate = (value: number): void => {
        dateUpdatedTimes.value++

        if (!value) {
            return
        }

        date.value = value
    }

    if (isRef(timestamp)) {
        watch(timestamp, updateDate)
    }

    if (watchValue) {
        watch(watchValue, updateDate)
    }

    tryOnMounted(() => autoUpdate && autoUpdateTimer.start())

    tryOnScopeDispose(() => autoUpdateTimer.stop())

    return computed<string | undefined>(() => {
        // Для тригера
        // eslint-disable-next-line no-unused-expressions
        dateUpdatedTimes.value

        if (!date.value) {
            return
        }

        if (format) {
            if (timezoneForFormat) {
                return dateUtilConfig
                    .applyOnlyLocale(dateUtil.rawFromSeconds(date.value))
                    .setZone(timezoneForFormat)
                    .toFormat(format)
            }

            return dateUtil
                .fromSeconds(date.value)
                .toFormat(format)
        }

        return dateUtil
            .formatHumanDate(dateUtil.fromSeconds(date.value), {
                short: true,
                relativeCalendar
            })
    })
}
